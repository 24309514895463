import React, { useEffect, useState, useRef } from "react";

export const Hackathon = (props) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [leaderboard2, setLeaderboard2] = useState([]);
  const [selectedType, setSelectedType] = useState("Overall"); // New state for type selection

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const formRef = useRef(null);

  // const url = "http://127.0.0.1:8000";
  const url = "https://api.letbabytalk.com";

  const fetchLeaderboard = async () => {
    const response = await fetch(url + "/leaderboard1");
    const data = await response.json();
    console.log(data);

    // Sort data by rank (default sorting)
    const sortedData = [...data].sort((a, b) => a.rank - b.rank);

    // Set sorted data to leaderboard
    setLeaderboard(sortedData);

    // Update the sortConfig state
    setSortConfig({ key: "rank", direction: "asc" });
  };

  const fetchLeaderboard2 = async () => {
    const response = await fetch(url + "/leaderboard2");
    const data = await response.json();
    console.log(data);

    // Sort data by rank (default sorting)
    const sortedData = [...data].sort((a, b) => a.rank - b.rank);

    // Set sorted data to leaderboard
    setLeaderboard2(sortedData);

    // Update the sortConfig state
    setSortConfig({ key: "rank", direction: "asc" });
  };

  useEffect(() => {
    fetchLeaderboard();
    fetchLeaderboard2();
  }, []);

  const handleFileUpload = async (event) => {
    event.preventDefault();
    // const teamName = event.target.team.value;
    const form = event.target;
    const teamName = form.team.value; // Access the value of the "team" input
    const csvFile = form.csv.files[0];
    const formData = new FormData(event.target);
    formData.append("type", selectedType); // Pass the selected type to the API

    console.log(teamName, csvFile);

    if (!teamName || !csvFile || !selectedType) {
      alert("Please fill in required elements");
      return;
    }

    const response = await fetch(url + "/submit", {
      method: "POST",
      body: formData,
    });
    if (response.ok) {
      const result = await response.json();
      const scores = Object.entries(result.scores)
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ");
      alert(
        `Submission successful!\nTeam: ${result.team}\nTimestamp: ${result.timestamp}\nType: ${selectedType}\nScore: ${scores}`
      );
      if (formRef.current) {
        formRef.current.reset();
      }
      if (selectedType == "Overall") {
        fetchLeaderboard();
      } else {
        fetchLeaderboard2();
      }
    } else {
      const result = await response.json();
      alert(`Submission failed! Error: ${result.error}`);
    }
  };
  const handleSort = (key) => {
    console.log("sort1 with ", key);
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    // Sort the leaderboard based on key and direction
    const sortedData = [...leaderboard].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    console.log("sorted", sortedData);
    setLeaderboard(sortedData);
  };

  const handleSort2 = (key) => {
    console.log("sort2 with ", key);
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    // Sort the leaderboard based on key and direction
    const sortedData = [...leaderboard].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    console.log("sorted", sortedData);
    setLeaderboard2(sortedData);
  };

  return (
    <div style={{ fontFamily: "Arial, sans-serif", margin: "20px" }}>
      <h1 style={{ textAlign: "center", color: "#2c3e50" }}>
        LetBabyTalk Project
      </h1>

      <section style={{ marginBottom: "40px" }}>
        <h2>Submit Your Result</h2>
        <form
          ref={formRef}
          onSubmit={handleFileUpload}
          style={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          <input
            type="text"
            name="team"
            placeholder="Team Name"
            style={{
              padding: "5px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          />
          <input
            type="file"
            name="csv"
            accept=".csv"
            style={{
              padding: "5px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          />

          <select
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            style={{
              padding: "7px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            <option value="Overall">Overall</option>
            <option value="Specific">Specific</option>
          </select>

          <button
            type="submit"
            style={{
              backgroundColor: "#27ae60",
              color: "white",
              border: "none",
              padding: "8px 16px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Submit
          </button>
        </form>
      </section>

      <section>
        <h2>Leaderboard</h2>
        <p>
          D1 score: The test set consists entirely of new babies (babies not
          seen during training).
        </p>
        <p>
          D2 score: The test set includes a mixture of old babies (babies seen
          during training) and new babies.
        </p>
        <h3>Best Overall Performance</h3>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              {[
                "rank",
                "team",
                "timestamp",
                "d1_score ",
                "d2_score",
                "total_score",
              ].map((column) => (
                <th
                  key={column}
                  onClick={() => handleSort(column)}
                  style={{
                    textTransform: "capitalize",
                    cursor: "pointer",
                    borderBottom: "2px solid #bdc3c7",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((item, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "#ecf0f1" : "white",
                }}
              >
                <td style={{ padding: "10px" }}>{item.rank}</td>
                <td style={{ padding: "10px" }}>{item.team}</td>
                <td style={{ padding: "10px" }}>{item.timestamp}</td>
                <td style={{ padding: "10px" }}>{item.d1_score?.toFixed(2)}</td>
                <td style={{ padding: "10px" }}>{item.d2_score?.toFixed(2)}</td>
                <td style={{ padding: "10px" }}>
                  {item.total_score.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <h3>Best Specific Performance</h3>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              {[
                "rank",
                "team",
                "timestamp",
                "d1_score ",
                "d2_score",
                "total_score",
              ].map((column) => (
                <th
                  key={column}
                  onClick={() => handleSort2(column)}
                  style={{
                    textTransform: "capitalize",
                    cursor: "pointer",
                    borderBottom: "2px solid #bdc3c7",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {leaderboard2.map((item, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "#ecf0f1" : "white",
                }}
              >
                <td style={{ padding: "10px" }}>{item.rank}</td>
                <td style={{ padding: "10px" }}>{item.team}</td>
                <td style={{ padding: "10px" }}>{item.timestamp}</td>
                <td style={{ padding: "10px" }}>{item.d1_score?.toFixed(2)}</td>
                <td style={{ padding: "10px" }}>{item.d2_score?.toFixed(2)}</td>
                <td style={{ padding: "10px" }}>
                  {item.total_score.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
};
